import React from "react";
import { Seo, PageHero } from "../components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";
import Church from "../images/noun-church-1853285.svg";
import GroupStudy from "../images/noun-study-group-409803.svg";
import OneOnOneStudy from "../images/noun-speech-therapy-1645504.svg";

const AboutWrapper = styled.div`
  ${tw`max-w-[1300px] mx-4 md:mx-[70px]`}

  .links-container {
    ${tw`grid grid-cols-3 grid-rows-1 my-4 gap-4 md:gap-8`}
  }

  .text-container {
    ${tw`bg-secondary rounded-2xl mt-8 mb-8 p-4 shadow-one md:p-12`}
  }

  .text-container section:not(:first-of-type) {
    ${tw`mt-8`}
  }

  .pastor-section {
    ${tw`w-full flex`}
  }

  .pastor-photo {
    ${tw`min-w-[80px] h-[80px] mx-2 md:(min-w-[160px] h-[160px] mx-4) lg:(min-w-[220px] h-[220px]) rounded-full overflow-hidden`}
  }
`;

const LinkCardWrapper = styled.div`
  ${tw`block h-full py-2 px-3 rounded-2xl bg-secondary flex flex-col items-center transition duration-500 ease-in-out shadow-one`}

  &:hover {
    ${tw`hover:(cursor-pointer shadow-three)`}
    transform:scale(0.9);
  }

  .icon-div {
    ${tw`w-1/2 h-1/2`}
  }

  svg {
    ${tw`w-full h-full`}
  }

  h3 {
    ${tw`text-center text-sm md:text-xl`}
  }

  p {
    ${tw`text-center md:text-base`}
  }

  p.action {
    ${tw`font-bold mt-2`}
  }
`;

const LinkCard = ({ icon, title, text, actionText }) => {
  const Icon = icon;

  return (
    <LinkCardWrapper>
      <div className="icon-div">
        <Icon fill="var(--text-nav)" />
      </div>

      <h3>{title}</h3>
      <p>{text}</p>
      <p className="action">{actionText}</p>
    </LinkCardWrapper>
  );
};

const OutLinkCard = ({ icon, title, text, actionText }) => {
  return (
    <a
      href="https://us05web.zoom.us/j/89893961944"
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkCard icon={icon} title={title} text={text} actionText={actionText} />
    </a>
  );
};

const InLinkCard = ({ icon, title, text, actionText }) => {
  return (
    <Link to="/contact">
      <LinkCard icon={icon} title={title} text={text} actionText={actionText} />
    </Link>
  );
};

const AboutPage = () => {
  return (
    <>
      <Seo
        title="About"
        description="Learn more about our service, mission and origin."
      />
      <PageHero
        img={
          <StaticImage
            src={"../images/scott-webb-Ur-jCHaQ2AE-unsplash.jpg"}
            alt="A high rise buiding which glass reflects the blue of a clear sky."
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title="About"
        text="University Bible Fellowship - Young Disciple Christian Fellowship"
      />
      <div className="page-main">
        <AboutWrapper>
          <div className="links-container">
            <OutLinkCard
              icon={Church}
              title="Sunday Service"
              text="Sundays 11a.m."
              actionText="Join via Zoom"
            />
            <OutLinkCard
              icon={GroupStudy}
              title="Group Study"
              text="Wednesdays 6p.m."
              actionText="Join via Zoom"
            />
            <InLinkCard
              icon={OneOnOneStudy}
              title="1 on 1 Bible Study"
              text="By Appointment"
              actionText="Contact Us"
            />
          </div>
          <article className="text-container" id="about-section">
            <section>
              <h3>Mission Statement</h3>
              <p>
                University Bible Fellowship (UBF) is an international
                evangelical church (non-denominational) and network of house
                churches dedicated to Christ and his kingdom. Our main focus is
                to study the Bible, grow in the grace and knowledge of our Lord
                and Savior Jesus Christ, and live according to his teachings. We
                especially pray to reach college students and help them grow as
                his lifelong disciples. Our goal is to obey our Lord's commands
                to love one another and to go and make disciples of all nations
                (John 13:34; Matthew 28:18-20). We pray that God may continue to
                call and raise lay missionaries through us and send them to the
                ends of the earth (Acts 1:8).
              </p>
            </section>
            <section>
              <h3>Ministry History</h3>
              <p>
                University Bible Fellowship church (UBF) began as a student
                movement in Korea in 1961. Today, UBF headquarters is in
                Chicago, USA, and local chapters exist in ninety-six countries
                around the world. UBF Canada began in 1981 when missionaries
                from Korea came to Winnipeg, Manitoba. As of March 2022, there
                are 18 local UBF chapters in different cities across Canada. The
                Ryerson chapter began in the year 2000 and its focus is
                college/university students and young adults downtown Toronto.
              </p>
            </section>
            <section>
              <h3>Pastor Charles</h3>
              <div className="pastor-section">
                <p>
                  Charles Kim is the Pastor of Young Disciple Christian
                  Fellowship - UBF at Ryerson. He studied MA in Economics
                  (Western University), and MSc in Health Science (Western
                  University). He has worked at Ryerson University and Banks. He
                  has been serving campus ministry over 35 years as a
                  self-supporting lay Pastor. Pastor Charles studies Doctor of
                  Ministry program at University of Toronto. His Research area
                  is “Mentorship and discipleship among emerging adults.”
                </p>

                <StaticImage
                  src={"../images/Pastor_Charles.jpg"}
                  alt="Pastor Charles photo"
                  placeholder="blurred"
                  layout="constrained"
                  width={220}
                  className="pastor-photo"
                  quality={100}
                />
              </div>
            </section>
          </article>
        </AboutWrapper>
      </div>
    </>
  );
};

export default AboutPage;
